function myOwl(alvo, opcoes){
	if($(alvo).children().length > 1){
	$(alvo).owlCarousel(opcoes);
	}else{
	$(alvo).addClass('owl-loaded');
	}
}

myOwl('#bem-vindo', {
	responsive:{
		0:{
			items: 1
		},
		600:{
			items: 2
		},
		992:{
			items: 4
		}
	},
	nav: true,
	autoplay:true,
  autoplayTimeout:5000,
  autoplayHoverPause:true,
	navText: [
		'<i class="fa fa-angle-left"></i>',
		'<i class="fa fa-angle-right"></i>'
	],
	loop: true,
});
myOwl('#cursos', {
	responsive:{
		0:{
			items: 1
		},
		600:{
			items: 2
		},
		992:{
			items: 3
		}
	},
	nav: true,
	autoplay:true,
  autoplayTimeout:5000,
  autoplayHoverPause:true,
	navText: [
		'<i class="fa fa-angle-left"></i>',
		'<i class="fa fa-angle-right"></i>'
	],
	loop: true,
});
myOwl('#clientes', {
	responsive:{
		0:{
			items: 2
		},
		600:{
			items: 3
		},
		992:{
			items: 3
		}
	},
	nav: true,
	autoplay:true,
  autoplayTimeout:5000,
  autoplayHoverPause:true,
	navText: [
		'<i class="fa fa-angle-left"></i>',
		'<i class="fa fa-angle-right"></i>'
	],
	loop: true,
});


myOwl('#carousel-enquete',{
	responsive: {
		992: {
			items: 4
		},
		600: {
			items: 2
		},
		0 : {
			items: 1
		}
	},
	nav: true,
	navText: [
		'<i class="fa fa-angle-left"></i>',
		'<i class="fa fa-angle-right"></i>'
	],
	loop: true
});